import { Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Input } from "../../components/InputFields";
import { FormLayout } from "../../components/Layout";
import { addMessage } from "../../state/reducers/appReducer";
import { fetchBaseOptions } from "../../utils/fetch";
import { EmailSchema } from "../../utils/validators";

const RequestActivationLinkPage = () => {
  const dispatch = useDispatch();
  return (
    <FormLayout seo={{ title: "Request a new activation link" }}>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={EmailSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          fetch("/api/user/activate", {
            ...fetchBaseOptions,
            body: JSON.stringify(values),
          })
            .then(async res => {
              const j: IAPIResponse = await res.json();
              dispatch(addMessage(j));
              resetForm();
            })
            .catch(err => console.error(err))
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="max-w-md mx-auto space-y-2">
            <Field required={true} type="email" name="email" label="Email" component={Input} />
            <button
              className="w-full btn btn-primary"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
};

export default RequestActivationLinkPage;
